import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import NavbarWrapper from '@pagerland/common/src/components/Navbar';
import Theme, { theme } from '@pagerland/themes/src/RealEstate';
import {
  Navbar,
  Welcome,
  About,
  Location,
  Exterior,
  Interior,
  Surroundings,
  Gallery,
  Contact,
  SimilarProperties,
  Footer,
} from '@pagerland/themes/src/RealEstate/containers';
import Container from '@pagerland/common/src/components/Container';
import Logo from '@pagerland/themes/src/RealEstate/components/Logo';



import SEO from '../components/SEO';

const Legal = ({ ContainerProps }) => (
  <Theme>
    <Helmet>
      <link href={theme.typography.googleFont} rel="stylesheet" />
    </Helmet>
    <SEO lang={'de'} title={'Impressum - Erdbau Siegl'} themeColor={theme.colors.primary} description={"Impressum - Erdbau Siegl"} />
    <Container {...ContainerProps}>
      <Link to={'/'}><Logo /></Link>
      <h1>Impressum</h1>
      <p>Erdbau Siegl / Kevin Siegl</p>
      <p>Prangerstraße 33, 7091 Breitenbrunn <br />Austria</p>
      <p>Erlaubte Tätigkeiten (Erdbau)</p>
      <p>Tel: +43 660 704 9388<br />Email: office@erdbau-siegl.at</p>
      <p>UID Nr: ATU78882345</p>
      <p>Mitglied der WKO Burgenland</p>
      <p>Bezirkshauptmannschaft Eisenstadt</p>
      <p>Baugewerbetreibender, eingeschränkt auf Erdbau<br />Österreich</p>
      <p>Berufsrecht: <br />Gewerbeordnung: <a href={'www.ris.bka.gv.at'} style={{ color: 'white' }}>www.ris.bka.gv.at</a></p>
      <p>Verbraucher haben die Möglichkeit, Beschwerden an die OnlineStreitbeilegungsplattform der EU zu richten: <a href={'http://ec.europa.eu/odr'} style={{ color: 'white' }}>http://ec.europa.eu/odr</a>.</p>
      <p>Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.</p>
    </Container>
  </Theme>
);

Legal.defaultProps = {
  ContainerProps: {
    py: 24,
  },
}

export default Legal;
